




























































































import {Component, Vue} from 'vue-property-decorator';
import {PublicBoardTemplateQuery} from "@/graphql/boardTemplate";
import {PublicBoardTemplate} from "@/typescript/types";
import {CreateBoardFromTemplateMutation} from "@/graphql/board";
import {WorkspaceQuery} from "@/graphql/workspace";

@Component({
  components: {},
  apollo: {
    PublicBoardTemplate: {
      query: PublicBoardTemplateQuery,
      variables() {
        return {
          id: this.$route.params.template_id,
        };
      },
      result(data) {
        this.templateName = data.data.PublicBoardTemplate.name;
      }
    },
  },
})
export default class TemplatePreview extends Vue {

  selectedPermission: string | null = 'workspace';
  loading: boolean = false;
  templateName: string | null = 'New room';
  copyAllData: string = this.$route.params.copyall;



  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  unauthenticatedCreateFromTemplate(template: PublicBoardTemplate) {
    this.$store.commit('set_template', template);
    this.$router.push({ name: 'register' });
    //@ts-ignore ToDo: fix type issue
    this.$parent.close();
  }

  createRoomFromTemplate() {
    this.loading = true;
    setTimeout(() => this.loading = false, 1500);
    this.$apollo
      .mutate({
        mutation: CreateBoardFromTemplateMutation,
        variables: {
          name: this.templateName,
          workspace: this.workspaceId,
          template_id: this.$route.params.template_id,
          permissions: this.selectedPermission,
          copyall: (this.$route.params.copyall) ? this.$route.params.copyall : false
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
      })
      .then(({data}) => {
        this.$gtag.event('pmb_room_created_from_template');
        this.$router.push({ name: 'room', params: { board_pid: data.createBoardFromTemplate.pid }});
      })
      .finally(() => {
      })
      .catch((_error) => {
        Vue.prototype.$consoleLog(_error)
      })
    ;
  }

  mounted() {
  
  }
}
